// プライバシーポリシー
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

const PrivacyPolicy = props => {
  //タイトル等
  const siteName = props.data.site.siteMetadata.title;
  const title = `プライバシーポリシー | ${siteName}`;
  const description = `当ブログにおけるプライバシーポリシーです。`;

  return (
    <Layout title={title} description={description} pageType="privacy-policy">
      <div className="mb-12">
        <h1 className="text-3xl mb-4 md:text-5xl font-bold">
          プライバシーポリシー
        </h1>
        <p>
          本文章は当ブログにおける個人情報の保護およびその適切な取り扱いについての方針を示したものです。
        </p>
      </div>
      <div className="mb-12">
        <h2 className="text-2xl mb-4 md:text-4xl font-bold">
          個人情報の利用目的
        </h2>
        <p className="mb-3">
          あなたのサイト名(以下当ブログ)では、お問い合わせの際に、名前、メールアドレス等の個人情報をご登録いただく場合がございます。
        </p>
        <p>
          これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
        </p>
      </div>
      <div className="mb-12">
        <h2 className="text-2xl mb-4 md:text-4xl font-bold">
          個人情報の第三者への開示
        </h2>
        <p className="mb-3">
          当ブログでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        </p>
        <ul className="mb-3 list-inside list-disc">
          <li>本人のご了解がある場合</li>
          <li>法令等への協力のため、開示が必要となる場合</li>
        </ul>
        <p>
          ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
        </p>
      </div>
      <div className="mb-12">
        <h2 className="text-2xl mb-4 md:text-4xl font-bold">
          広告の配信について
        </h2>
        <p className="mb-3">
          当は第三者配信の広告サービス「Google Adsense
          グーグルアドセンス」を利用しています。
        </p>
        <p className="mb-3">
          広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。
        </p>
        <p className="mb-3">
          Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は「
          <a
            className="text-blue-300"
            href="https://policies.google.com/technologies/ads?gl=jp"
            target="_blank"
            rel="noreferrer"
          >
            広告 – ポリシーと規約 – Google
          </a>
          」をご覧ください。
        </p>
        <p className="mb-3">
          また、当ブログでは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、
          Amazonアソシエイト・プログラムの参加者です。
        </p>
        <p>
          第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにCookie（クッキー）を設定したりこれを認識したりする場合があります。
        </p>
      </div>
      <div className="mb-12">
        <h2 className="text-2xl mb-4 md:text-4xl font-bold">
          アクセス解析ツールについて
        </h2>
        <p className="mb-3">
          当ブログでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        </p>
        <p>
          このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
        </p>
        <p>
          このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
        </p>
        <p>
          この機能はCookieを無効にすることで収集を拒否することが出来ますので、
          お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは
          <a
            className="text-blue-300"
            href="https://marketingplatform.google.com/about/analytics/terms/jp/"
            target="_blank"
            rel="noreferrer"
          >
            ここをクリック
          </a>
          してください。
        </p>
      </div>
      <div>
        <h2 className="text-2xl mb-4 md:text-4xl font-bold">免責事項</h2>
        <p>
          当ブログで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。
        </p>
        <p>権利を侵害する目的ではございません。</p>
        <p className="mb-3">
          各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。
        </p>
        <p className="mb-3">
          当ブログからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
        </p>
        <p className="mb-3">
          当ブログのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。
        </p>
        <p className="mb-3">
          当ブログに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;

export const privacyPolicyQuery = graphql`
  query privacyPolicyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
